/* eslint-disable arrow-body-style */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

const StyledDiv = styled.div`
  @media (min-width: 992px) {
    height: 90vh;
  }
`

export const pageQuery = graphql`
  query ($databaseId: Int) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      databaseId
      templateSneakPreview {
        iframeUrl
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const SneakPreviewTemplate = ({ data: { page } }) => {
  return (
    <Layout>
      <>
        <SEO seo={page.seo} />
        <StyledDiv className="page-template">
          <iframe
            src={page.templateSneakPreview.iframeUrl}
            title="MWWIF"
            id="mww-frame-sneak-preview"
            className="mwwframe"
            width="100%"
            height="100%"
          />
        </StyledDiv>
        <Helmet>
          <script
            type="text/javascript"
            src="https://www.mtmo-ww.nl/js/iframe-show.js"
          />
        </Helmet>
      </>
    </Layout>
  )
}

export default SneakPreviewTemplate
